<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="vx-row">
                <div class="vx-col md:w-1/3 w-full mt-2">
                    <label class="vs-input--label">Địa điểm</label>
                    <v-select class="" v-model="placeModel" :clearable="false" :options="placeOptions"
                              data-vv-validate-on="blur" v-validate="'required'" name="place">
                              <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                    </v-select>
                    <span class="text-danger text-sm">{{ errors.first('place') }}</span>
                </div>
                <div class="vx-col md:w-2/3 w-full mt-2">
                    <vs-input class="w-full" label="Tên khu vực"
                              v-model="areaDetail.name"
                              v-validate="'required'" name="areaDetailName"/>
                    <span class="text-danger text-sm">{{ errors.first('areaDetailName') }}</span>
                </div>
            </div>

            <div class="flex flex-wrap items-center mt-5">
                <div class="flex-grow">
                    <label>Chọn các hạng mục cần kiểm tra.</label>
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                          v-model="searchQuery"
                          @input="updateSearchQuery" placeholder="Tìm kiếm..."/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="dailyWorkDetails"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="20"
                         :onGridReady="onGridReady"
                         :rowSelection="rowSelection"
                         :suppressRowClickSelection="true"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                        <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                `${isUpdate ?
                                    'Cập nhật' : 'Tạo mới'}`
                            }}
                        </vs-button>
                        <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.go(-1)">Quay lại
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererDetailActions from "./cell-renderer/CellRendererDetailActions.vue"

export default {
    components: {
        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRendererDetailActions,
    },
    data() {
        return {
            isUpdate: false,
            rowSelection: 'multiple',
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            areaDetail: {
                name: null,
                placeId: null
            },
            defaultColDef: {
                flex: 1,
                minWidth: 100,
                resizable: true,
                headerCheckboxSelection: this.isFirstColumn,
                checkboxSelection: this.isFirstColumn,
            },
            placeOptions: [],
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 50
                },
                {
                    headerName: 'Các hạng mục cần kiểm tra',
                    field: 'name',
                    width: 200
                },
                {
                    headerName: 'Phương pháp',
                    field: 'methodCheck',
                    width: 150,
                },
                {
                    headerName: 'Yêu cầu công việc',
                    field: 'completionCriteria',
                    width: 200,
                }
            ],
            // Cell Renderer Components
            components: {
                CellRendererDetailActions,
            },
            dailyWorkDetails: []
        }
    },
    created() {
        this.initScreen(this);
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateArea'(val) {
            if (val) {
                this.initScreen(this);
                this.$store.commit('UPDATE_AREA_DETAIL', false);
            }
        }
    },
    computed: {
        placeModel: {
            get() {
                return {
                    label: this.getLabelString(this.placeOptions.find(x => x.value == this.areaDetail.placeId)),
                    value: this.areaDetail.placeId
                }
            },
            set(obj) {
                this.areaDetail.placeId = obj.value
            }
        },

        validateForm() {
            return !this.errors.any()
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        isFirstColumn(params) {
            let displayedColumns = params.columnApi.getAllDisplayedColumns();
            let thisIsFirstColumn = displayedColumns[0] === params.column;
            return thisIsFirstColumn;
        },

        getLabelString(object) {
            return (object && object.label) || 'Chưa xác định';
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                if (!this.areaDetail.placeId) {
                    return this.$vs.notify({
                        text: "Địa điểm chưa được chọn",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }
                if (this.gridApi.getSelectedNodes().length == 0) {
                    return this.$vs.notify({
                        text: "Chưa có hạng mục kiểm tra nào được chọn.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }

                this.areaDetail.workDetailedIdList = this.gridApi.getSelectedNodes().map(node => {
                    return node.data.id;
                });
                this.areaDetail.placeIdList = [this.areaDetail.placeId];
                this.areaDetail.placeId;
                let url = this.isUpdate ? `/area/update/${this.areaDetail.id}` : `/area/create`,
                    data = this.areaDetail;
                this.$vs.loading();
                this.$crm.post(url, data).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? 'Cập nhật' : 'Thêm'} khu vực thành công`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$router.push(`/user/area`).catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen(that) {
            this.$vs.loading();
            this.$crm.get('/work-detailed/find-all').then((response) => {
                this.dailyWorkDetails = response.data;
            }).then(() => {
                this.$crm.get('/building-type/find-all').then((place) => {
                    this.placeOptions = place.data.map(place => {
                        return {
                            label: place.name,
                            value: place.id
                        }
                    });
                    this.$vs.loading.close();
                });
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    title: 'Error',
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            }).finally(() => {
                that.gridApi.sizeColumnsToFit();
                let id = this.$route.query.id;
                if (id) {
                    that.isUpdate = true;
                    that.$crm.get('/area/find-by-id/' + id).then((place) => {
                        place.data.placeId = place.data.buildingTypeList[0].id;
                        that.areaDetail = place.data;
                        that.areaDetail.workDetailedList.forEach(work => {
                            that.gridApi.forEachNodeAfterFilter(function (node) {
                                if (node.data.id == work.id) {
                                    node.setSelected(true);
                                }
                            });
                        });
                        that.$vs.loading.close();
                    });
                }

            });
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
